import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Page from '../Page';
import { Col, Row, Spinner } from 'react-bootstrap';
import {
    Link,
    Redirect,
    Route,
    Switch,
    useParams,
    useRouteMatch,
} from 'react-router-dom';
import TournamentQuestionsPage from '../TournamentQuestionsPage';
import TournamentBatchesPage from '../TournamentBatchesPage';
import BatchPage from '../BatchPage';
import PaperPage from '../Paper/PaperPage';
import TournamentAppealsPage from '../TournamentAppealsPage';
import TournamentResultsPage from '../TournamentResultsPage';
import TournamentSectionLinks from '../TournamentSectionLinks';
import InputterParticipantPage from '../Inputter/InputterParticipantPage';
import VenueResultsInputPage from '../VenueResultsInputPage';
import ResultsInputPage from '../ResultsInputPage';
import JuryTournamentResultsInputPage from './JuryTournamentResultsInputPage';
import TournamentProblemMarksStatisticsPage from '../TournamentProblemMarksStatisticsPage';

const juryTournamentPageQuery = gql`
    query JuryTournamentPage($number: Int!) {
        tournament(number: $number) {
            _id
            number
            stage
            seasons {
                fall {
                    stage
                    ordinary {
                        date
                        stage
                    }
                    advanced {
                        date
                        stage
                    }
                    criteriaInternalLink
                }
                spring {
                    stage
                    ordinary {
                        date
                        stage
                    }
                    advanced {
                        date
                        stage
                    }
                    criteriaInternalLink
                }
            }
        }
    }
`;

export default function JuryTournamentPage() {
    let { path } = useRouteMatch();
    const params = useParams<{ number: string }>();
    const number = Number(params.number);
    const { data, loading } = useQuery(juryTournamentPageQuery, {
        variables: { number },
    });
    const { t } = useTranslation();

    if (loading) {
        return (
            <Page>
                <div className="text-center">
                    <Spinner animation="border">
                        <span className="sr-only">{t('Loading')}</span>
                    </Spinner>
                </div>
            </Page>
        );
    }

    const { tournament } = data;

    return (
        <Switch>
            <Route exact={true} path={path}>
                <Page>
                    <JuryTournamentView tournament={tournament} />
                </Page>
            </Route>
            <Route
                path={`${path}/questions/:round`}
                component={TournamentQuestionsPage}
            />
            <Route
                path={`${path}/appeals/:round/:tier`}
                component={TournamentAppealsPage}
            />
            <Route
                exact={true}
                path={`${path}/batches/:round/:tier`}
                component={TournamentBatchesPage}
            />
            <Route
                exact={true}
                path={`${path}/batches/:batchId`}
                component={BatchPage}
            />
            <Route
                path={`${path}/batches/:batchId/papers/:paperId`}
                component={PaperPage}
            />
            <Route
                exact={true}
                path={`${path}/results`}
                component={TournamentResultsPage}
            />
            <Route
                exact={true}
                path={`${path}/marks-statistics`}
                component={TournamentProblemMarksStatisticsPage}
            />
            <Route path={`${path}/papers/:paperId`} component={PaperPage} />
            <Route
                path={`/tournaments/:number/results-input`}
                component={JuryTournamentResultsInputPage}
            />
            <Route
                path={`/tournaments/:number/participants/:participantId`}
                component={InputterParticipantPage}
            />
            <Route
                path={`/tournaments/:number/venues/:venueId/results-input`}
                exact={true}
                component={VenueResultsInputPage}
            />
            <Route
                path={`/tournaments/:number/venues/:venueId/results-input/:round/:tier/:grade?`}
                component={ResultsInputPage}
            />
            <Redirect to={path} />
        </Switch>
    );
}

export function JuryTournamentView({ tournament }) {
    const { t } = useTranslation();
    const { number } = tournament;

    return (
        <>
            <h2>
                {t('TournamentTitle', {
                    number,
                })}
            </h2>
            <Row>
                <Col md={4}>
                    <h4>Вопросы</h4>
                    <TournamentSectionLinks
                        number={number}
                        stage={tournament.stage}
                        section="questions"
                        depth="LEVEL"
                    />
                </Col>
                <Col md={4}>
                    <h4>Пачки</h4>
                    <TournamentSectionLinks
                        number={number}
                        stage={tournament.stage}
                        section="batches"
                        depth="TIER"
                    />
                </Col>
                <Col md={4}>
                    <h4>Апелляции</h4>
                    <TournamentSectionLinks
                        number={number}
                        stage={tournament.stage}
                        section="appeals"
                        depth="TIER"
                    />
                </Col>
            </Row>
            <br />
            <Row>
                <Col md={4}>
                    {tournament.seasons.fall.criteriaInternalLink && (
                        <>
                            <h6>Критерии</h6>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={
                                    tournament.seasons.fall.criteriaInternalLink
                                }
                            >
                                Осень
                            </a>
                            <br />
                            {tournament.seasons.spring.criteriaInternalLink && (
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={
                                        tournament.seasons.spring
                                            .criteriaInternalLink
                                    }
                                >
                                    Весна
                                </a>
                            )}
                        </>
                    )}
                </Col>
                <Col md={4}>
                    <h4>
                        <Link to={`/tournaments/${number}/results`}>
                            Результаты
                        </Link>
                    </h4>
                    <Link to={`/tournaments/${number}/marks-statistics`}>
                        Статистика оценок
                    </Link>
                    <br />
                    <br />
                    <Link to={`/tournaments/${number}/results-input`}>
                        Вбить
                    </Link>
                    <br />
                </Col>
            </Row>
            <br />
            <br />
            <br />
        </>
    );
}
